#back-to-top {

    transition: opacity .5s ease;
    position: fixed;
    right: 20px;
    bottom: 20px;
    display: none;
    width: 40px;
    height: 40px;
    font-size: 12px;
    text-align: center;
    line-height: 40px;
    color: var(--jile-color);
    background-color: var(--color10);
    cursor: pointer;
    z-index: 9999;
    opacity: .4
}

#back-to-top:hover {
    opacity: 1
}
