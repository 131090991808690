.preview-image-container {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: #000;
    background-color: rgba(0,0,0,.65);
    /* transition: transform .3s ease; */
    z-index: 211;
}

.preview-image-container a::after {
    content: 'x';
    /* color: #fff; */
    color: #434646;
    /* text-shadow: -4px 2px 6px #000 */
    font-weight: bold;
}

.preview-image-container a {
    border: 1px solid var(--color4);
    background-color: var(--color4);
    border-radius: 100%;
    font-size: 28px;
    float: right;
    position: absolute;
    top: 10px;
    right: 10px;
	width: 1em;
    height: 1em;
    padding: 4px 1px;
    cursor: pointer
}

.preview-image-wrapper a:hover {
    text-decoration: none
}

.preview-image-container.full {
    display: block;
    padding: 0;
}

.preview-image-wrapper {
    display: table-cell;
    height: 100vh;
    width: 100vw;
    text-align: center;
    /* background: #0f0; */
    line-height:100%;
    vertical-align: middle;
    -webkit-transform: scale(1);
    transform: scale(1);
    transition: -webkit-transform .3s ease;
    transition: transform .3s ease;
}
.preview-image-wrapper img{
    display:inline-block;
    max-width: 90% !important;
    max-height: 100vh !important;
}
.preview-image-container.full img {
    box-shadow: 0 0 0;
    cursor: auto;
}
.parent-overlay {
    overflow: hidden
}