header {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 200;
    transition: all .5s ease;
    display: block;
}

.nav-header {
    position: absolute;
    top: 0;
    left: auto;
    right: 0;
    box-shadow: var(--box-shadow1);
    transition: right .4s ease;
    /* padding: 3px 0 4px; */
    z-index: 100;
    background-color: var(--color4);
    width: 100%;
    /*transition: background-color 0.5s ease;*/
}

.nav-navbar {
    height: auto;
    /* transition: height .4s ease; */
}

.nav-header .container {
    position: relative;
}

.nav-header li {
    display: inline-block;
    padding: 10px 0;
}

.menu-set a {
    /* color: var(--color3); */
    color: #404040;
	 /* color: var(--color-new-1); */
    padding: 15px 12px;
    font-size: 16px;
    /*text-transform: uppercase;*/
    text-transform: capitalize;
}

.menu-set a:hover {
    color: var(--jile-color);
}

.menu-set .btn,
.menu-set .btn:hover {
    padding: 10px 25px;
    font-size: 16px; 
}

.logo-section {
    position: absolute;
    height: 100%;
    padding: 0;
}

.logo-container {

    height: inherit;
}

.logo {
   display: block;
    height: 50px;
    margin-top: 7px;
    background-image: url(/assets/img/logo.png);
    background-repeat: no-repeat;
    background-position: left center;
    line-height: 30px;
    background-size: contain;
}

.top-set {
    background-color: var(--color4);
    /*transition: background-color 0.5s ease;*/
}

.top-menu-set {
    position: relative;
    float: inherit;
    padding-top: 5px;
}

.bottom-set {
    position: relative;
    background-color: var(--color6);
	 /*color: var(--color-new-1);*/
    /* background-color: var(--jile-color); */
    /*transition: background-color 0.5s ease;*/

}

.bottom-set .mobile-menu {
    display: none;
}

.bottom-menu-set {
    position: relative;
    transition: 0.3s;
}

/* .bottom-menu-set li{
    padding-left:20px;
    padding-right:20px;
} 
.bottom-menu-set li:last-child,
.bottom-menu-set li:last-child a {
    padding-right: 3px;
}*/


/* 
    *
    * sub menu  
    *
*/
.dropdown {
    position: relative;
}

.dropdown li {
    display: block;
}

.dropdown>.dropdown-toggle {
    position: relative;
    min-width: 0;
    font-weight: 400;
    border: none;
    z-index: 1;
}

.dropdown>.dropdown-toggle:before {

    transition: bottom .1s ease-in, border 50ms ease-in, box-shadow .3s ease-in;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 100%;
    display: block;
    content: "";

    border-style: solid;
    border-width: 0;
    z-index: -1;
}

.dropdown>.dropdown-toggle:after {
    display: inline-block;
    margin-left: 3px;
    width: 15px;
    content: "\f0d7";
    font-family: FontAwesome;
    font-size: 14px;
    text-align: center;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.dropdown>.dropdown-menu {
    display: none;
    transform: translateY(-7px);

    margin: 0;
    padding: 0;
    background-color: var(--color4);
    border: none;
    /* box-shadow: 0 6px 12px #24344a3b; */
    box-shadow: none;
    position: absolute;
    z-index: 1000;
}

.dropdown.opened>.dropdown-toggle {

    transition: color .1s ease .25s;
    color: var(--color3);
    box-shadow: inset 0px 3px 0px var(--jile-color);
    

    /* z-index: 1000; */
}

.dropdown.opened>.dropdown-toggle::before {
    box-shadow: 0 6px 12px #24344a3b;
    bottom: 0;
    background-color: var(--color4);
}

.dropdown.opened>.dropdown-menu {
    display: block;
	box-shadow: 4px 5px 7px 0px grey;
}

.dropdown-menu>li>a:hover,
.dropdown-menu>li>a:focus {
    /* text-decoration: none; */
    color: inherit;
    background-color: inherit;
}


.dropdown-menu .dropdown>.dropdown-toggle:after {
    position: absolute;
    bottom: -1px;
    right: 0;
	height: 100%;
    display: inline-block;
    width: 20px;
    content: "\f0da";
    font-family: FontAwesome;
    font-size: 16px;
    line-height: 42px;
    text-align: center;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.dropdown>.dropdown-menu.dropdown-sub-menu {

    top: 0;
    left: 100%;
    transform: translateY(0px) translateX(-3px);
    background-color: var(--color4);
    /* box-shadow: 0 6px 12px #24344a3b; */
	    box-shadow: 4px 2px 7px 0px grey;

}

.dropdown-menu li {
    padding: 0px;
}

.dropdown-menu li a {
    padding: 10px 10px;
	padding-left: 20px;
    display: block;
}

.dropdown-menu li a:hover,
.dropdown-menu li a:focus {
    background-color: var(--color6);
    color: var(--color3);
}

.dropdown-menu .dropdown.opened {
    background-color: var(--color6);
    /* box-shadow: 0 6px 12px #24344a3b; */
}

/* .dropdown-menu .dropdown.opened>.dropdown-toggle{
    box-shadow: inset 3px 0px 0px var(--jile-color);
} */
.dropdown-menu .dropdown>.dropdown-toggle {
    box-shadow: none;
}

.dropdown-menu .dropdown.opened>.dropdown-toggle::before {
    box-shadow: none;
    background-color: var(--color6);
}

/* 
    *
    * divided menu  
    *
*/
.dropdown-menu{
	min-width: 180px;
}
.dropdown-split-menu {
    min-width: 325px;
    min-width: 355px;
    min-width: 450px;
}

.dropdown-split-menu>li {
    float: left;
    text-align: left;
    width: 50%;
}
.about .dropdown-split-menu {
     min-width: 235px;
	  min-width: 250px;
}

/* 
    *
    * error  menu  
    *
*/
 .error-header .top-set>.container{
		   height:62px;
		 }
		.error-header.head-opacity .bottom-set{
			background-color:transparent;
		 }
		 .error-header .collapsed .navigation-toggle{
			 display:none;
		 }
/* 
    *
    * mobile  
    *
*/
body.collapsed {
    right: 270px;
}

.navigation-toggle {
    border-radius: 4px;
    background-clip: padding-box;
    /* position: absolute; */
    position: relative;
    float: right;
    top: 13px;
    right: 5px;
    display: none;
    min-width: 0;
    padding: 9px 10px;
    border: none;
    background: 0 0;
    outline: 0;
}


.collapsing {
    overflow: visible;
}

.navigation-toggle .icon-bar {

    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
    opacity: .7;
    background-color: var(--jile-color);
    margin-top: 4px;

}

body.collapsed .nav-header {
    right: 270px;
}

.collapsed .navigation-toggle {
    display: block;
}

.collapsed .top-menu-set li {
    display: none;
}

.collapsed .top-menu-set .always-show {
    display: inline-block;
}

.collapsed .bottom-set {

    transform: translateZ(0);
    transition: right .4s ease;
    position: absolute;
    top: 0;
    right: -270px;
    display: block;
    width: 270px;
    padding: 0;
    text-align: center;
    background-color: var(--color5);
    overflow-x: hidden;
    overflow-y: auto;
    direction: rtl;
}

/* .collapsed .bottom-set .mobile-menu {
    display: block;
    } */

.collapsed .logo-empty-space {
    display: none;
}

.collapsed .bottom-set .container {
    padding: 0px;
}

.collapsed .bottom-set li {
    width: 100%;
    padding: 0px;

}

.collapsed .bottom-set li a {
    width: 100%;
    color: var(--color4);
    border-style: solid;
    border-width: 1px 0;
    border-color: var(--border-color1);
    display: block;
    text-align: center;
    padding: 25px 0;

}


.collapsed .bottom-menu-set li:last-child{
    padding-right: 0px;
}
.collapsed .bottom-menu-set li:first-child a {
    padding-left: 10px;
}

.collapsed .dropdown.opened .dropdown-toggle {
    color: var(--color4);
    /* background-color: transparent; */
    background-color: var(--color2);
    box-shadow: none;
}
.collapsed .dropdown.opened .dropdown-menu{
    background-color: var(--color2);
	    box-shadow: none;
}
.collapsed .dropdown.opened .dropdown-sub-menu{
    background-color: var(--color32);
	    box-shadow: none;
}
.collapsed .dropdown>.dropdown-menu{
    /* background-color: transparent; */
    position: relative;
    top:0;
    left:0;
    right:0;
    min-width:100%;
}

.collapsed .dropdown.opened>.dropdown-toggle::before {
    
    display: none;
}
.collapsed  .dropdown>.dropdown-toggle::after{
    position:relative;
    display:inline;
    content: "\f0d7";
    margin-left: 7px;
}
.collapsed .dropdown-split-menu>li {
    width: 100%;
    float: none;

}
.collapsed .about .dropdown-split-menu>li {
    width: 100%;
    float: none;
}
.collapsed .about .dropdown-split-menu{
transform: translate(0px);
}
.collapsed .dropdown-menu li a{
	padding:10px 15px;
}
.collapsed .dropdown-menu li a:hover, .collapsed .dropdown-menu li a:focus{
	background-color:transparent;
}
.collapsed  .dropdown-menu .dropdown>.dropdown-toggle:after{
		bottom:0px;
	}
/* navigation header new code */
/* 
.sign-in{
  border: 1px solid var(--jile-color);
    padding: 3px 0 6px !important;
	padding: 4px 0 6px !important;
    border-radius: 20px 0 0 20px;
    margin-right: -6px;
}*/
.sign-in {
	 /*margin-right: -6px;*/
	     padding: 12px 30px;
		 margin-right:6px;
}
.try-now{
  /*border-radius: 0 20px 20px 0 !important;*/
      padding: 12px 30px;
  border:1px solid var(--jile-color);
}
.head-opacity .nav-header {
  box-shadow: 0 0 0;
  background-color: transparent;
  transition: background-color 0.1s ease;
}
.head-opacity .top-set {
  box-shadow: var(--box-shadow1);
}
.head-opacity .bottom-set {
 
  /* background-color: #053c56d4; */
  background-color: var(--color-menu-1);
  background-color: var(--color-menu-2);
  transition: background-color 0.1s ease;
}
.head-opacity .bottom-set .menu-set ul a{
  color: var(--color4);
}
.head-opacity .bottom-set .menu-set ul a:hover{
  color: var(--jile-color);
  text-shadow: 0 0 0;
  transition: color 0.1s ease;
}
.head-opacity .bottom-set .menu-set ul .dropdown li a{
  color: var(--color3);
  transition: color 0.1s ease;
}
.head-opacity .dropdown.opened>.dropdown-toggle {
  color: var(--color3);
}

.sol-dropdpwn .dropdown-split-menu {
    min-width: 400px;
}
.head-opacity .bottom-set .menu-set ul .dropdown li a.elab-menu,.dropdown-menu a.elab-menu {
    color: var(--color2);
    font-weight: 500;
}
.dropdown-menu a.elab-menu:hover, .dropdown-menu a.elab-menu:focus {
    background-color: inherit;
}
.head-opacity .bottom-set .menu-set ul a.elab-menu:hover,
.head-opacity .bottom-set .menu-set ul a.elab-menu:focus {
    text-shadow: none;
}
a.elab-menu:hover,
a.elab-menu:focus{
    cursor: default;
}
.elab-li li li{
    margin-left: 10px;
}
.collapsed .elab-li li li a{
    background-color: #2e3133;
}
.collapsed .dropdown .elab-menu:after {
    display: inline;
    margin-left: 7px;
    /* width: 15px; */
    content: "\f0d7";
    font-family: FontAwesome;
    font-size: 14px;
    text-align: center;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}
.collapsed .menu-set ul .dropdown li a.elab-menu {
    color: var(--color16);
    font-style: italic;
}
.collapsed .bottom-set li a:not([href]):not([tabindex]),.collapsed .bottom-set li a:not([href]):not([tabindex]):focus,.collapsed .bottom-set li a:not([href]):not([tabindex]):hover{
color: var(--color4);
}
/* 
    *
    * media query 
    *
*/
@media(max-width:1250px) {
	.about .dropdown-split-menu{
		transform: translate(-160px,-7px);
	}
	.res-dropdown .dropdown-split-menu{
		transform: translate(-231px,-7px);
	}
	
}
@media(max-width:1024px) {
	.dropdown-split-menu{
		min-width: 450px;
	}
	.dropdown-split-menu li a{
		   padding: 10px 20px;
	}
	.about .dropdown-split-menu{
		transform: translate(-210px,-7px);
		    min-width: 300px;
	}
	.res-dropdown .dropdown-split-menu{
		transform: translate(-275px,-7px);
		min-width: 400px;

	}
}

@media (max-width:991px) {

    .menu-set {
        padding: 0;
    }
	.menu-set a{
			padding: 15px 5px;
			font-size: 15px;
	}
	.res-dropdown .dropdown-split-menu{
		transform: translate(-289px,-7px);
	}
     /*.bottom-menu-set li {
        padding-left: 10px;
        padding-right: 10px;
    }

   .bottom-menu-set li:first-child,
    .bottom-menu-set li:first-child a {
        padding-left: 0px;
    }

    .bottom-menu-set li a {
        padding: 12px 20px;
    }
	.dropdown>.dropdown-toggle{
		  padding: 15px 20px;
	}
*/
	.about .dropdown-split-menu {
		    min-width: 286px;
	}
	.about .dropdown-split-menu li a{
		padding:10px 15px;
	}
	.about .dropdown-split-menu>li{
		    width: 45%;
	}
	.dropdown-menu .dropdown>.dropdown-toggle:after{
		bottom:-4px;
	}
}
@media(max-width:850px){
.bottom-menu-set{
	float:left;
}
}
@media(max-width:767px) {
    body {

        cursor: default;
        transition: right .4s ease;

        position: relative;
        right: 0;
    }
	.menu-set a{
		font-size:16px;
	}
    .bottom-menu-set {
        float: none;

    }
    /* .try-now {
      border-radius: 20px 5px 5px 20px !important;
    } */

	.res-dropdown .dropdown-split-menu {
		transform: none;
	}

}

@media(max-width:425px) {}

@media(max-width:320px) {}