
/* @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:600&display=swap'); */
/* @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i&display=swap'); */
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,600,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
    margin: 0;
    padding: 0;
    border: 0;
    /* font-size: 100%; */
    vertical-align: baseline;
   
}
ol,
ul {
    list-style: none;
}
* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased !important;
}



::selection {
    background-color: var(--jile-color);
    color:var(--color4);
}

.container:before,
.container:after,
.row:before,
.row:after,
.nav-header:before,
.nav-header:after,
.nav-navbar:before,
.nav-navbar:after,
.top-set:after,
.top-set:before,
.bottom-set:after,
.bottom-set:before {
    content: " ";
    display: table;
}


.row:after,
.nav-header:after,
.nav-navbar:after,
.top-set:after,
.bottom-set:after {
    clear: both;
}

body {
    font-family: var(--font-family1);
	 font-family: var(--font-family2);
    /*font-family: var(--font-family2);*/
    font-size: 16px;
    font-weight: 400;
    /* color: var(--color1); */
    color: #404040;
   
    /* line-height: 1.5625; */
    line-height: 1.5em;
    background-color: var(--color4);
    overflow-x: hidden;
    min-height: 100px;
	 cursor: default;
   
}


a{
    cursor: pointer;
    /* color: var(--jile-color);
    color: var(--color-new-1);*/
    /* color: var(--color-new-2); */
    color: blue;
    
}
p{
    margin: 0 0 10px;
    line-height: 1.57em;
}
h1,h2,h3,h4,h5,h6 {
    margin: 0 0 10px;
    /* line-height: 1.4; */
    line-height: 1.5;
}
a:hover, a:focus {
     /*color: var(--jile-color);
    color: var(--color-new-1);*/
	 color: var(--color-new-2);
    text-decoration: none;
    outline: none;
}
.container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
    /* width: 98%; */
    /* position: relative; */
}
.btn,.btn:hover, .btn:focus,.btn:active:focus {
    position: relative;
    display: inline-block;
    background-clip: padding-box;
	background-color: transparent;
    /*transition: color .2s ease,border .2s ease,background .2s ease,box-shadow .2s ease;*/
	transition:none;
    min-width: 60px;
    max-width: 100%;
    padding: 20px 30px;
	opacity: 0.95;
    outline: none;
    border-width: 1px;
    border-style: solid;
    border-radius: 6px;
    border-color: var(--jile-color);
	font-size: 16px;
	font-weight: 500;
	text-transform: capitalize;
    line-height: 1;
	box-shadow:none;
	
}

.btn.btn-outline,.btn.btn-outline:hover,.btn.btn-outline:focus{
    background-color: var(--color4);
    /* color: var(--color3); */
	/* color: var(--jile-color); */
    color: #202020;
    outline: none;
	border-color:var(--jile-color);
}
.btn.btn-solid,.btn.btn-solid:hover,.btn.btn-solid:focus{
    background-color: var(--jile-color);
	/* color: var(--color4); */
    color: #202020;
   /*color:var(--color-new-1); 
   color: var(--color4);
	color:black;*/
	
    /*border-radius: 20px;
	padding: 12px 30px*/
		
}

/*.btn-outline{
  background-color: var(--color4);
  color: var(--color3);
  padding: 10px 25px;
  border-radius: 20px;
  background-clip: padding-box;
  border: 1px solid var( --jile-color);
}
.btn-outline:hover {
  background-color: var(--color4);
  color: var(--jile-color) !important;
  border-radius: 20px;
  border: 1px solid var(--jile-color);
}*/
.btn.btn-outline-left,.btn.btn-outline-left:hover{
	/*border-radius: 20px 0 0 20px;*/
	border-radius: 6px;
   
}
/* .clearfix:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
} */



/* 
    *
    *Genderal 
    *
*/
.light-text{
    color:var(--color4);
}
.color-jile-iron{
    color:var(--color3);
}
.float-right{
    float:right;
  
}
.float-left{
    float:left;
  
}
.style-italic{
	font-style:italic;
}
.uppercase {
    text-transform: uppercase;
}
.padding-left-zero{
    padding-left:0px;
}
.padding-right-zero{
    padding-right:0px;
}
.padding-zero{
	padding:0px;
}
.margin-left-zero{
	margin-left:0px;
}
hr.space-top {
    display: inline-block;
    width: 0;
    height: 1px;
    border: none;
    /* background-color: #e9e9e9; */
    margin-top:40px;
}
hr.no-margin{
	border:none;
}
hr.no-margin-six{
	margin-top:6em;
}
.highlight{
    /* color:var(--jile-color); */
    color: #404040;
}
.text-lowercase{
	text-transform: lowercase;
}


.align-center {
    /* text-align: center; */
    text-align: left;
}
.sbold {
    font-weight: 600 !important;
}

.fs-14 {
    font-size: 14px !important;
}

.fs-13 {
    font-size: 13px !important;
}

.fs-22 {
    font-size: 22px !important;
}

.fs-18 {
    font-size: 18px !important;
}

.fs-19 {
    font-size: 19px !important;
}

.fs-15 {
    font-size: 15px !important;
}

.fs-20 {
    font-size: 20px !important;
}

.fs-11 {
    font-size: 11px !important;
}

.font-grey {
    color: grey !important;
}

.pb-20 {
    padding-bottom: 20px !important;
}

.pt-25 {
    padding-top: 25px !important;
}

.pt-5 {
    padding-top: 5px !important;
}

.pt-8 {
    padding-top: 8px !important;
}

.pr-5 {
    padding-right: 5px !important;
}

.pl-15 {
    padding-left: 15px !important;
}
/* 
    *
    *media query 
    *
*/

@media(max-width:1024px){

}
@media (max-width:991px){

}
@media(max-width:767px){
    /* .container{
        width: 100%;
        padding: 0 5px;
        
    } */

    .btn{
        max-width:100%;
    }

    
}
@media(max-width:425px){

}
@media(max-width:320px){

}



.text-up {
	text-transform: uppercase;
}
.text-low {
	text-transform: lowercase;
}
body {
    
    font-family: var(--font-family2);
}
.header-color{
	color: var(--color-new-1);
}
.second-header-color{
	/* color: var(--color3); */
    color: #404040;
}

.centeral-content-header{
	    
	font-size: 34px;
	font-weight: 500;
    
	text-align: center;
}
.feature-heading .centeral-content-para{
	font-size: 26px;
	font-weight: 400;
	
	text-align: center;
	line-height: 1.3;
}