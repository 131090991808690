@import './style-common.css';
/* @import './share/style-video-pop-up.css'; */
/*@import './share/style-video.css';*/
@import './share/style-preview-img.css';
/*@import './share/style-home-offer.css';*/


body {
  color: var(--color1);
}
  
.home-hero{
    padding-bottom: 0px;
    background-position: right top;
    margin-top: -20px;
    min-height: 92vh;
    background-image: url('/assets/img/homePage/homehero.png');
	 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: scroll;
    position: relative;
	
	/* dec-changes
	background-image: url('/assets/img/homePage-dec/homehero.png');*/
}
.home-hero .container {
    
    margin-top: 20px;
}




.heading-block>h4 {
  
  font-weight: 700 !important;
  color: var(--color7);
  font-size: 35px;
  margin-bottom: 40px;
  line-height: 1.3;
}
.heading-block{
    width: 40%;
    position: absolute;
    bottom:40px;
}

.heading-block img{
    width:80%;
}

#home-hero-video{
    max-height: 500px;
    width: 50%;
    position: absolute;
    
    right: 0;
    bottom: 15%;
} 

.dark-gray{
  color: var(--color8);
  text-shadow: 2px 1px 1px rgba(241, 236, 236, 0.71) !important;
}

.btn.release {
    font-weight: bold;
    margin-bottom: 10px;
}

.home-hero img{
  position: absolute;
  bottom: 0;
  width: 100%;
}
.home-hero .home-try-btn,
.home-hero .home-try-btn:hover,
.home-hero .home-try-btn:active,
.home-hero .home-try-btn:focus{
    font-weight: bold;
    margin-bottom: 10px;
    /* border: 1px solid #31c7b2; 
    border-radius: 0 20px 20px 0;  */
    padding-left: 63px; 
    padding-right: 63px;
}
.home-hero .home-req-demo,
.home-hero .home-req-demo:hover,
.home-hero .home-req-demo:active,
.home-hero .home-req-demo:focus{
    font-weight: bold;
    margin-bottom: 10px; 
    margin-left: 5px;
    /* border-radius: 20px 0 0 20px; 
    margin-right: -6px; */
}
@media (max-width:991px) {
  .home-hero .heading-block {
  width:49%;
}
  .home-hero {
  background-position: left top;
}
}
@media (max-width:850px) {
  .heading-block>h4 {
    line-height: 1.1;
    margin-bottom: 10px;
  }
  .home-hero .heading-block {
    bottom:0;
  }
    .home-hero .home-req-demo,
.home-hero .home-req-demo:hover,
.home-hero .home-req-demo:active,
.home-hero .home-req-demo:focus{
    margin-left: 0;
}
}
@media (max-width:800px) {
  .home-hero{
    background-image: url('/assets/img/homePage/homeheroMob.png');
	background-attachment: scroll;
    background-position-x: right;
	background-size: cover;
	min-height: 600px;
	/* dec-changes
	 background-image: url('/assets/img/homePage-dec/homeheroMob.png');*/
	 /* background-position-y: bottom; */
  }
   
  .heading-block>h4 {
    text-shadow: 2px 1px 1px rgba(62, 36, 36, 0.71) !important; 
    color: var(--jile-color);
    line-height: 1.1;
    margin-bottom: 10px;
  }
  .home-hero .heading-block {
    bottom:0;
	
  }
}
@media(max-width:767px) {
  .home-hero .heading-block {
      width: 94%;
	  /* dec-changes*/
	  /* top:100px; */
  }
  /* dec-changes*/
  .home-hero .home-try-btn,
.home-hero .home-try-btn:hover,
.home-hero .home-try-btn:active,
.home-hero .home-try-btn:focus{
	padding:15px 49px;
     
}
/* dec-changes*/
  .home-hero .home-req-demo,
.home-hero .home-req-demo:hover,
.home-hero .home-req-demo:active,
.home-hero .home-req-demo:focus{
    padding: 15px 15px;
}
  /*.heading-block > h4 {
    text-shadow: 1px 1px 2px var(--color2);
    }*/
}


/* Ipad */

@media (min-width: 768px) {
  @media (min-height: 1024px) {
      .home-hero {
          background-attachment: scroll;
		  min-height: 80vh;
      }
      /*.home-hero .heading-block {
        bottom: 18%;
      }*/
      .heading-block>h4 {
        text-shadow: 2px 1px 1px rgba(62, 36, 36, 0.71) !important; 
        color: var(--jile-color);
      }
  }
}

@media (min-width: 1024px) {
  @media (min-height: 768px) {
      .home-hero {
          background-attachment: scroll;
          background-position: center center;
      }
      .heading-block>h4 {
        text-shadow: 2px 1px 1px rgba(62, 36, 36, 0.71) !important; 
        color: var(--jile-color);
      }
  }
}

@media (max-width: 500px) {
    .home-hero .home-try-btn,
    .home-hero .home-try-btn:hover,
    .home-hero .home-try-btn:active,
    .home-hero .home-try-btn:focus{ 
        margin-left: auto;
    }
  }

/* nav */

.sign-in{
    padding: 4px 0 6px !important;
}


/*
*
* slide show
*
*/
.jile-support-dad-content{
  position: relative;
  overflow: hidden;
}
.slide-container {
      position: relative;
      margin: auto;
    }
.slide-wrapper {
      display: none;
    }
.slide-wrapper img{
      max-height: inherit;
      width: 100%;
    }
.slide-contant{
    text-align:center;
  }
.slide-contant-heading{
    font-size: 35px;
    font-weight: 600;
    line-height: 1.5em;
  }
.slide{
        max-height: 400px;
    }
.prev, .next {
      cursor: pointer;
      position: absolute;
      top: 45%;
      width: auto;
      margin-top: -22px;
      padding: 16px;
      color: black;
      font-weight: bold;
      font-size: 18px;
      -webkit-transition: 0.6s ease;
      -o-transition: 0.6s ease;
      transition: 0.6s ease;
      border-radius: 0 3px 3px 0;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
    }
.next {
      right: 0;
      border-radius: 3px 0 0 3px;
    }
.dad-slide-dot-background .dad-slide-dot{
      cursor: pointer;
      height: 5px;
      width: 5px;
      margin: auto;
      background-color: transparent;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      border-radius: 50%;
      position: absolute;
      -webkit-transition: all .8s ease;
      -o-transition: all .8s ease;
      transition: all .8s ease;

    }
.dad-slide-dot-background{
      cursor: pointer;
      height: 15px;
      width: 15px;
      margin: 5px 2px;
      padding: 0;
      -webkit-box-shadow: 0 1px 8px 0 #053c56;
              box-shadow: 0 1px 8px 0 #053c56;
      background-color: transparent;
      border-radius: 50%;
      position: relative;
      margin-right: 8px;

    }
.dad-slide-dot-group{
      position: relative;
      padding: 16px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      left: 44%;
      
    }
.jile-support-dad-boby .shown .dad-slide-dot {
          background-color: #053c56;
          -webkit-box-shadow: 0 0 6px 0 #053c56;
                  box-shadow: 0 0 6px 0 #053c56;
      }
.slide-show{
      -webkit-animation-name: slide-show;
      -webkit-animation-duration: 1.5s;
      animation-name: slide-show;
      animation-duration: 1.5s;
    }
.slide-show-text{
      -webkit-animation-name: slide-show-text;
      -webkit-animation-duration: 1.5s;
      animation-name: slide-show-text;
      animation-duration: 1.5s;
    }
    .slide-group{
      position: relative;
      overflow: hidden;
    }
    .slide-text{
      font-size: 18px;
      text-align: center;
      color: var(--color1);
      padding: 2em 3em 0 0;
    }
    .slide-text h4{
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 0;
  }
  .slide-text p{
    padding: 1.5em 1.5em;
    border-radius: 0 0 5px 5px;
    border:0;
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: 100px auto;
    min-height: 12em;
  }
  .jile-tools .btn-outline,
  .builtin-quality .btn-outline{
    border: 2px solid var( --jile-color)
  }

  .jile-tools .btn.btn-outline,
  .builtin-quality .btn.btn-outline{
    font-weight: 600;
  }
.agile-slider-heading-container{
  background-color: var(--color7);
  margin-bottom: 2em;
}
    .agile-slider-heading{
        color: var(--color4);
        font-size:26px;
        font-weight: 600;
        margin: 1em 0;
        text-align: center;
    }
    .builtin-quality .btn.btn-outline,
    .jile-tools .btn.btn-outline,
    .jile-wow .btn.btn-outline {
    display: table;
    margin: auto;
    margin-top: 1.5em;
    }
    .adopt-h4 h4{
      color: #70ad47;
    }
  
@-webkit-keyframes slide-show{
      from {
         
          opacity: .2;
          /* transform: scale( 1.1); */
          /* transform: translateX(100%); */
      } 
      to {
         
          opacity: 1;
          /* transform: scale( 1); */
          /* transform: translateX(0); */
      }
    }
@keyframes slide-show {
      from {
          
          opacity: .2;
          /* transform: scale( 1.1); */
          /* transform: translateX(100%); */
      } 
      to {
          
          opacity: 1;
          /* transform: scale( 1); */
          /* transform: translateX(0); */
      }
    }
    @-webkit-keyframes slide-show-text{
    from {
         
        transform: translateX(100%);
    } 
    to {
       
        transform: translateX(0);
    }
    }
    @keyframes slide-show-text {
      from {
          transform: translateX(100%);
      } 
      to {
          transform: translateX(0);
      }
    }
@media (max-width: 1024px){
      .slide-wrapper img{
          padding-top: 7vh; 
       }

    }
@media (max-width: 991px){
      
          .slide-text {
            padding: 0;
        }
          .dad-slide-dot-group{
              left: 41%;
          }
      }
@media (max-width: 767px){
  .slide-wrapper img {
    padding-top: 0vh;
  }
  .prev, .next{
    top: 66%;
  }
  .dad-slide-dot-group{
    left:42%;
  }
  .slide-contant-heading{
    line-height: 1em;
    padding-bottom: 1em;
  }
  .slide-text {
    padding-top: 15px;
}
  .slide-text p {
    min-height: 0;
  }
  .agile-slider-heading {
    font-weight: 500;
    font-size: 18px;
  }
}
@media (max-width: 600px){
  .dad-slide-dot-group{
    left:41%;
  }
}
@media (max-width: 500px){
  .dad-slide-dot-group{
    left:39%;
  }
}
@media (max-width: 425px){
          .dad-slide-dot-group {
              left: 37%;
          }
      }
@media (max-width: 375px){
          .dad-slide-dot-group {
              left: 35%;
          }
      }
@media (max-width: 320px){
          .dad-slide-dot-group {
              left: 32%;
          }
      }


/*
*
* jile-wow
*
 */
/* .jile-wow{
  background-image: url('/assets/img/homePage/wowBg.jpg');
  background-size: cover;
  padding: 2em 0;
  color: var(--color6);
  margin-top: 1em;
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
} */
.jile-wow p {
  text-shadow: 2px 2px 4px var(--color2);
} 
.jile-wow p a{
  text-shadow: 0 0 0;
} 
.jile-wow h4{
  font-size: 36px;
    font-weight: 500;
    margin-bottom: 20px;
    text-shadow: -3px 0px 2px black;
}
.jile-wow h4>span{
  color: var(--jile-color);
}
.jile-wow .row img{
    width: 60%;
    display: block;
    margin: auto;
}
.wow-text{
  text-align: center;
  padding: 0 3em;
  margin-top: 1em;
}
@media (max-width:800px) {
  .jile-wow {
    background-attachment: scroll;
    background-size: 250% auto;
  }
  .wow-text{
    padding: 0 15px;
  }
}
/* Ipad */

@media (min-width: 768px) {
  @media (min-height: 1024px) {
      .jile-wow {
          background-attachment: scroll;
      }
  }
}

@media (min-width: 1024px) {
  @media (min-height: 768px) {
      .jile-wow {
          background-attachment: scroll;
      }
  }
}

/*
*
* jile-tools
*
 */

 .jile-tools-heading{
  text-align: center;
  font-weight: 600;
  /* color: var(--color3); */
  color: #404040;
  /* margin-bottom: 1em; */
  }
  .product-feature {
    background-image: url('/assets/img/homePage/toolsbg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    margin-top: 2em;
    position: relative;
}
.product-overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.25;
    z-index: 1;
}
.jile-tools{
  margin: 2em 0 1em;
  z-index: 2;
  position: relative;
  text-shadow: 0 0 5px black;
}


 .jile-tools h4{
   text-align: center;
   font-weight: 600;
   margin-bottom: 20px;
   color: var(--jile-color);
 }
 /* .jile-tools span{
   color: var(--jile-color);
 } */
 .jile-tools p{
   text-align: center;
   color: var(--color4);
 }
 .tools-img{
   min-height: 6.5em;
 }
 .row .tools-img img{
  height:70px;
  width: auto;
  display: block;
  margin: auto;
 }
 .tools-white{
  padding: 1em;
 }
 @media (max-width:1199px) {
  .tools-img {
    min-height: 5em;
  }
 }
 @media (max-width:991px) {
  .tools-img {
    min-height: 6em;
  }
  .tools-white {
    min-height: 16em;
}
 }
 @media (max-width:767px) {
  .jile-tools p {
    text-align: center;
}
 }



/* 
    *
    * slider-section
    *

*/

blockquote:after,
blockquote:before {

    position: absolute;
    top: 25%;
}

blockquote:before {
    left: 0;
}

blockquote:after {
    right: 0;
}

.slider-section h2 {
    font-size: 45px;
}

@media(max-width:1024px) {}

@media (max-width:991px) {}



@media(max-width:425px) {}

@media(max-width:320px) {}


/* 
    *
    *Half half Resources section
    *
 */
 /* .half-half{
   margin:2em 0;
 } */
 .half-half .row{
  padding: 0 15px;
 }
 .half-half .col-sm-6{
  padding: 0 0px;
 }
 .half-half h4{
  font-size: 24px;
}
 .half-half a{
  font-weight: 600;
}
 .half-half i{
  margin-left: 2px;
  vertical-align: middle;
}
.ebook-part{
  background-image: url('/assets/img/homePage/ebookbgi.png');
  /* color: var(--color2); */
}
.blog-part{
  background-image: url('/assets/img/homePage/blogbgi.png');
  color: var(--color4);
}
.webinar-part{
  background-image: url('/assets/img/homePage/webinarbgi.png');
  color: var(--color4);
}
.tutorial-part{
  background-image: url('/assets/img/homePage/tutorialbgi.png');
  /* color: var(--color2); */
}
.knowledge-part{
  background-image: url('/assets/img/homePage/agilebasicbgi.png');
  /* color: var(--color2); */
}
.datasheet-part{
  background-image: url('/assets/img/homePage/dataSheeti.png');
  color: var(--color4);
}
.half-half .row>div>div{
  padding: 2em;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom right;
  min-height: 18em;
  padding-right: 40%;
}
@media (max-width:1199px) {
  .half-half .row>div>div{
    min-height: 20em;
  }
}
@media (max-width:991px) {
  .half-half .row>div>div{
    min-height: 23em;
    background-position:left center;
  }
}
@media (max-width:767px) {
  .half-half .row>div>div{
    min-height: 15em;
  }
}
@media (max-width:425px) {
  .half-half .row>div>div{
    padding: 2em;
    background-size: auto 150%;
  }
}
@media (max-width:767px) {
  .half-half .pull-right{
    float: none !important;
  }
}



/*
                        *
                        *Why_jile  form Effect
                        *
                    */

#why-jile .fa {
    color: var(--jile-color);
    font-size: 35px
}

#why-jile .focal-point {
    height: auto;
}
/* 
article .heading {
    color: var(--color1);
    font-weight: 600;
    margin-top: 10px;
    font-size: 20px;
}

article p {
    color: var(--color1);
} */

#why-jile hr.no-margin {
    width: 0;
    margin-top: 40px;
}

#why-jile .caption-overflow {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
}

#why-jile .caption-overflow span {
    position: relative;
    display: block;
    top: 40%;
    ;
}

#why-jile .caption-overflow span a {
    display: block;
}

#why-jile .caption-overflow img {
    width: 10%;
}

@media(max-width: 1400px) {
    #why-jile .focal-point .frame {
        margin-bottom: 0em;
        margin-top: 0em;
        margin-left: 0em;
        margin-right: 0em;
    }
}

@media(max-width: 1000px) {
    #why-jile .focal-point .frame {
        margin-left: -6em;
    }
}

@media(max-width: 767px) {
    #why-jile hr.no-margin {
        display: none;
    }
}

@media(max-width: 767px) {
    #why-jile .caption-overflow span {
        top: 45%;
    }

    #why-jile .focal-point .frame {
        margin-bottom: -2em;
        margin-top: -2em;
        margin-left: -69em;
        margin-right: -2em;
    }
}

@media(max-width: 650px) {
    #why-jile .focal-point .frame {
        margin-top: -1em;
        margin-left: -60em;
    }
}

@media(max-width: 550px) {
    #why-jile .caption-overflow img {
        width: 15%;
    }

    #why-jile .focal-point .frame {
        margin-left: -54em;
    }
}

@media(max-width: 500px) {
    #why-jile .focal-point .frame {
        margin-left: -49em;
        margin-top: 0em;
    }
}

@media(max-width: 450px) {
    #why-jile .focal-point .frame {
        margin-left: -43em;
        margin-right: -2em;
    }
}

@media(max-width: 400px) {
    #why-jile .focal-point .frame {
        margin-left: -37em;
    }
}

@media(max-width: 350px) {
    #why-jile .focal-point .frame {
        margin-left: -30em;
        margin-right: -5em;
    }
}

/*
                        *
                        *Talkie video  
                        *
                    */

#talkie-video {
    position: relative;
    background-image: url('/assets/img/background/BackgroundForJileTalkies.jpg');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    /*background-image: url(/assets/img/features/Jile/tryfreesection.jpg);*/
    background-repeat: no-repeat;
    background-position: center center;
    /*background-attachment: fixed;*/
}

#talkie-video .talkie-video-header {
    position: relative;
}

#talkie-video .talkie-video-author-name {
    text-shadow: 1px 0px 2px var(--color18);
    text-align: left;
    float: right;
}

.talkie-video-slider .talkie-video-author {
    height: 28vh;
}

.talkie-video-slider .talkie-video-says {
    height: 30vh;
}

#talkie-video .talkie-video-header h2 {
    font-size: 35px;
    line-height: 1.2em;
    color: var(--jile-color);
    font-weight: 600;
}

#talkie-video .talkie-video-slider {
    position: relative;
    min-height: 200px;
    overflow: hidden;
    -webkit-transition: all .8s ease-in;
    -moz-transition: all .8s ease-in;
    transition: all .8s ease-in;
    clear: both;
}

#talkie-video .talkie-video-slider:before {
    clear: both;
    display: table;
    content: "";
}

#talkie-video .talkie-video-slider:after {
    clear: both;
    /*display: table;*/
    content: "";
}

#talkie-video .left-slider {
    transform: translateX(-100%);
}

#talkie-video .right-slider {
    transform: translateX(100%);
}

#talkie-video .talkie-video-visible {
    transform: translateX(0);
}

@media (max-width:767px) {
    #talkie-video .talkie-video-header h2 {
        font-size: 27px;
    }
}

@media (max-width:1024px) {
    #talkie-video .talkie-video-slider {
        min-height: 200px;
    }

    #talkie-video .talkie-video-visible {
        padding: 0;
    }
}

@media(max-width:991px) {
    #talkie-video {
        background-size: cover;
        background-attachment: scroll;
        background-position: top;
    }

    /* .talkie-video-header{
                        margin: 37px 0 30px;
                    
                        } */
}

@media(max-width:900px) {
    #talkie-video .talkie-video-slider {
        min-height: 300px;
    }
}

@media (max-width: 768px) {
    #talkie-video .talkie-video-slider {
        min-height: 250px;
    }
}

@media (max-width: 550px) {
    .talkie-video-slider .talkie-video-says {
        height: auto;
        width: 100%;
    }
}

@media (max-width: 500px) {
    #talkie-video .talkie-video-slider {
        min-height: 350px;
    }
}

@media(max-width:480px) {
    #talkie-video .talkie-video-slider {
        min-height: 300px;
    }
}

@media (max-width: 400px) {
    #talkie-video .talkie-video-slider {
        min-height: 350px;
    }
}

@media(max-width:375px) {
    #talkie-video .talkie-video-slider {
        min-height: 300px;
    }

    #talkie-video .talkie-video-author-name {
        float: none;
        text-align: center;
    }

    .talkie-video-slider .talkie-video-author {
        height: auto;
        width: 100%;
    }
}

@media(max-width:340px) {
    #talkie-video .talkie-video-slider {
        min-height: 350px;
    }
}

@media(max-width:320px) {
    #talkie-video .talkie-video-slider {
        min-height: 400px;
    }
}

/* Ipad pro */

@media (max-width:1024px) {
    @media (min-height: 1366px) {
        #talkie-video {
            background-position: 0px 0px;
            background-attachment: scroll;
            /* min-height: 637px !important; */
        }

        .talkie-video-slider .talkie-video-author {
            height: 17vh;
        }

        .talkie-video-slider .talkie-video-says {
            height: 17vh;
        }
    }
}

@media (min-width: 1366px) {
    @media (min-height: 1024px) {
        #talkie-video {
            background-position: 0px -80px;
            background-attachment: scroll;
            /* min-height: 637px !important; */
        }

    }
}

/* Ipad */

@media (min-width: 768px) {
    @media (min-height: 1024px) {
        #talkie-video {
            background-position: 0px -80px;
            background-attachment: scroll;


        }

        .talkie-video-slider .talkie-video-author {
            height: 17vh;
        }

        .talkie-video-slider .talkie-video-says {
            height: 17vh;
        }
    }
}

@media (min-width: 1024px) {
    @media (min-height: 768px) {
        #talkie-video {
            background-position: 0px -80px;
            background-attachment: scroll;

        }

    }
}

/*
                        *
                        *Price  
                        *
                    */

.section .section-header {
    margin: 0 auto;
}

.section {
    padding-bottom: 0;
}



@media(max-width:767px) {
    #why-jile .clearfix {
        padding-top: 2em;
    }

    #why-jile .clearfix>div {
        padding: 0 3em;
    }
}



/*
                        *
                        *Resource  form Effect
                        *
                    */

#resources {
    padding: 6vh;

}

.resource-wrapper {
    position: relative;
    background: var(--color31);
    overflow: auto;
    display: block;
    padding: 0;
    clear: both;
    overflow: auto;
}

.column2 .resource-wrapper {
    float: left;
    overflow: hidden
}

.column1 .resource-wrapper img {
    height: auto;
}



.resource-wrapper h4 {
    color: var(--color24);
    font-size: 35px;
}

.resource-wrapper p {
    color: var(--color25);
}

.column2>div {
    padding: 0;
}



.column1 .resource-wrapper img {
    position: relative;
    display: inline-block;
    margin: 0;
    left: 0;
    top: 0;
    width: 50%;
    padding: 0;
}

.resource-content {
    position: relative;
    display: inline-block;
    bottom: -4vh;

}


.focal-point {
    overflow: hidden;

    padding: 0;
}

.focal-point,
.resource-content-parent {
    height: 236px;
}

.resource-content a {
    margin-bottom: 3vh;
}

.column2 .resource-wrapper {
    float: left;
}



.focal-point img {
    width: 100%;
    max-width: 100%;
    position: relative;
    -ms-interpolation-mode: bicubic;
}

.focal-point>div {
    position: relative;
}

@media all and (max-width: 1440px) {
    .focal-point .frame {
        margin: -3em -4em;
    }

    .column1 .up-1 .frame {
        margin-top: -6.5em;
        margin-bottom: -0.6em;
    }

    .column1 .right-1 .frame {
        margin-left: -17em;
        margin-right: -20em;
    }

    .row1 .up-3 .frame {
        margin-top: -10.5em;
        margin-bottom: -0.6em;
    }

    .row1 .right-3 .frame {
        margin-left: -7em;
        margin-right: -20em;
    }

    .row2 .up-3 .frame {
        margin-top: -1em;
        margin-bottom: -1em;
    }

    .row2 .right-3 .frame {
        margin-left: -8em;
        margin-right: -6em;
    }

    .focal-point.up-5 .frame {
        margin-top: 0em;
        margin-bottom: 0em;
    }

    .focal-point.right-5 .frame {
        margin-left: 0em;
        margin-right: 0em;
    }
}

@media (min-width: 1024px) {
    @media (min-height: 1366px) {
        .column1 .right-1 .frame {
            margin-left: -14em;
            margin-right: -15em;
        }

        .column1 .up-1 .frame {
            margin-top: -39em;
            margin-bottom: 0em;
        }

        #resources {
            padding: 6vh 0;
        }
    }
}

@media (max-width: 1277px) {
    .column1 .resource-wrapper img {
        height: 72vh;
    }
}

@media (max-width: 995px) {
    .column1 div {
        width: 100%;
    }

    .column1 .resource-wrapper img,
    .column2 .resource-wrapper>div {
        width: 100%;
    }

    .column2 .resource-content {
        bottom: 0;
    }

    .column1 .right-1 .frame {
        margin-left: 0em;
        margin-right: 0em;
    }

    .column1 .up-1 .frame {
        margin-top: -8.5em;
        margin-bottom: -0.6em;
    }

    .focal-point .frame {
        margin: 0;
    }

    .row1 .up-3 .frame {
        margin-top: -14.2em;
        margin-bottom: -0.64em;
    }

    .row1 .right-3 .frame {
        margin-left: -4em;
        margin-right: -23em;
    }

    .row2 .up-3 .frame {
        margin-top: -6.4em;
    }

    .focal-point.up-5 .frame {
        margin-top: -6em;
    }
}

@media (max-width: 780px) {
    #resources {
        padding: 0;
        padding-top: 6vh;
        padding-bottom: 6vh;
    }
}

@media (max-width: 767px) {
    .column2 .focal-point .frame {
        margin: 0;
    }

    .row1 .right-3 .frame {
        margin-left: -1em;
        margin-right: -4em;
    }

    .row1 .up-3 .frame {
        margin-top: -11.2em;
        margin-bottom: -0.64em;
    }

    .focal-point.up-5 .frame {
        margin-top: -6em;
    }

    .column1 .resource-wrapper img {
        height: auto;
    }

    .column1 {
        margin-bottom: 20px;
    }

    .column2>div {
        margin-bottom: 20px;
    }

    /* #resources {
        padding-bottom: 0;
    } */
}



@media (max-width: 600px) {
    .column1 .up-1 .frame {
        margin-top: -5.5em;
        margin-bottom: -0.6em;
    }

    .row1 .up-3 .frame {
        margin-top: -6.2em;
    }
}

@media (max-width: 580px) {
    .focal-point.up-5 .frame {
        margin-top: -3em;
    }
}

@media (max-width: 500px) {
    .column1 .up-1 .frame {
        margin-top: 0;
        margin-bottom: 0;
    }

    .row1 .up-3 .frame {
        margin-top: -3.2em;
    }

    .focal-point {
        height: auto;
    }

    .focal-point.up-5 .frame {
        margin-top: 0em;
    }
}

@media (max-width: 400px) {
    .row1 .up-3 .frame {
        margin-top: 0;
    }
}

/* Ipad pro */

@media (max-width:1024px) {
    @media (min-height: 1366px) {
        .resource-content {
            bottom: 0;
            top: 0;
        }

        #resources {
            padding: 6vh 0;

        }

        .resource-wrapper h4 {
            font-size: 30px;
        }

        .row2 .up-3 .frame {
            margin-top: 0;
        }

        .row2 .right-3 .frame {
            margin-left: -6em;
            margin-right: -6em;
        }

        .focal-point.up-5 .frame {
            margin-top: 0em;
            margin-bottom: 0em;
        }

        .focal-point.right-5 .frame {
            margin-left: -3em;
            margin-right: -3em;
        }
    }
}

@media (min-width: 1366px) {
    @media (min-height: 1024px) {
        .resource-content {
            bottom: 0;
        }

        #resources {
            padding: 6vh 0;

        }

        .resource-wrapper h4 {
            font-size: 30px;
        }

        .row2 .right-3 .frame {
            margin-left: -6em;
            margin-right: -6em;
        }

        .focal-point.up-5 .frame {
            margin-top: 0em;
            margin-bottom: 0em;
        }

        .focal-point.right-5 .frame {
            margin-left: -3em;
            margin-right: -3em;
        }
    }
}

/* Ipad */

@media (min-width: 768px) {
    @media (min-height: 1024px) {
        .resource-content {
            bottom: 0;
        }

        #resources {
            padding: 6vh 0;

        }

        .row2 .right-3 .frame {
            margin-left: -6em;
            margin-right: -6em;
        }

        .resource-wrapper h4 {
            font-size: 30px;
        }

        .focal-point.up-5 .frame {
            margin-top: 0em;
            margin-bottom: 0em;
        }

        .focal-point.right-5 .frame {
            margin-left: -3em;
            margin-right: -3em;
        }
    }
}

@media (min-width: 1024px) {
    @media (min-height: 768px) {
        .resource-content {
            bottom: 0;
        }

        #resources {
            padding: 6vh 0;

        }

        .row2 .right-3 .frame {
            margin-left: -6em;
            margin-right: -6em;
        }

        .resource-wrapper h4 {
            font-size: 30px;
        }

        .focal-point.up-5 .frame {
            margin-top: 0em;
            margin-bottom: 0em;
        }

        .focal-point.right-5 .frame {
            margin-left: -3em;
            margin-right: -3em;
        }
    }
}

/*
                        *
                        *feedback effects
                        *
                    */

blockquote:before {
    content: url('/assets/img/icon/qfirst-whites.png');
}

blockquote:after {
    content: url('/assets/img/icon/qsecond-whites.png');
}

#feedback:before {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: block;
    content: "";
    background-color: var(--color26);
    z-index: 0;
}


/*
            //////
            
            Written same class in below slider section
        
            //////
        
        #feedback-controls .customer-details {
                -webkit-transition: border 0.3s ease, opacity 0.3s ease;
                -moz-transition: border 0.3s ease, opacity 0.3s ease;
                -o-transition: border 0.3s ease, opacity 0.3s ease;
                transition: border 0.3s ease, opacity 0.3s ease;
                margin: 0 0 0 30px;
                padding: 15px 0;
                text-align: left;
                opacity: 0.75;
                cursor: pointer;
                vertical-align: bottom;
                position: absolute;
                bottom: 0;
                right:0;
            }*/
#feedback-controls .customer-feedback-slider {
    /*color:var(--color1);*/
    /* border-bottom: 2px solid #e9e9e9; */
    margin: 20px 0;
}

#feedback-controls .flex-manual>div {
    cursor: pointer;
}

#feedback-controls .customer-feedback-slider .customer-details p {
    color: var(--color1);
    padding-left: 80px;
}

#feedback-controls .customer-feedback-slider .customer-selected p,
#feedback-controls .customer-details:hover p {
    color: var(--color4);
}

#feedback-controls .customer-details p>span.highlight {
    color: var(--color1);
    text-transform: capitalize;
    font-weight: bold;
}

#feedback-controls .customer-details:hover p>span.highlight {
    color: var(--jile-color);
    /* color: rgba(255,255,255,1); */
}

#feedback-controls .customer-feedback-slider:before {
    content: '';
    display: table;
    clear: both;
}

#feedback-controls .customer-feedback-slider:after {
    clear: both;
    content: " ";
    display: table;
}

#feedback-controls .customer-details {
    cursor: pointer;
    margin: 0 2vw;
    transition: border 0.3s ease, opacity 0.3s ease, color 0.7s ease-in;
    /* border-bottom: 1px solid #e9e9e9; */
}
#feedback-controls .customer-selected a{
  font-weight: bold;
}


#feedback-controls .customer-selected {
    border-bottom: 2px solid var(--color21);
    /* font-weight: bold; */
}

#feedback-controls .customer-selected p>span.highlight {
    color: var(--jile-color);
}

.transparent-bg {
    background-color: var(--color22);
    background-color: var(--color23);
    position: absolute;
    width: 100%;
    bottom: 0;
}


@media (max-width:1024px) {

    blockquote {
        padding: 0 230px;
    }
}

@media(max-width:991px) {
    blockquote {
        padding: 0 125px;
    }

}

@media(max-width:900px) {
    blockquote {
        padding: 0 150px;
    }

}

@media (max-width: 768px) {
    blockquote {
        padding: 0 115px;
    }

}



@media (max-width: 620px) {
    blockquote {
        padding: 0 70px;
    }

}

@media (max-width: 500px) {
    #feedback {
        background-position: center center;
    }

    blockquote:before {
        top: 2%;
    }

}

@media(max-width:480px) {

    blockquote:after {
        top: 80%;
    }

    blockquote {
        padding: 0 53px;
    }
}



@media(max-width:375px) {

    blockquote:after {
        top: 85%;
    }

}

@media(max-width:340px) {

    blockquote:after {
        top: 90%;
    }
}








/* 
            *
            *new Slider start
            *
        */

#slide1_container {
    width: 100%;
    min-height: 150px;
    overflow: hidden;
    /* So the sliding bit doesn't stick out. */
    margin: 0 auto;

}

#slide1_images {
    /* This is the bit that moves. It has 3 images, so 3 * 100 = 300. You could use javascript
            to work this out instead by counting images. */
    width: 300%;
    /* For two image */
    -webkit-transition: all 1.0s ease-in-out;
    -moz-transition: all 1.0s ease-in-out;
    -o-transition: all 1.0s ease-in-out;
    transition: all 1.0s ease-in-out;
}

#slide1_images div {
    padding: 0;
    margin: 0;
    width: 33.33%;
    /* width:50%; For two image */
    float: left;
    /* All the images are in a row next to each other. */
    animation: 10s slidy infinite;
}

.slider-bg {
    background-image: url('../img/homePage/TestimonialImg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center center;
    text-align: center;
    position: relative;
}

.extra-padding {
    margin-top: 1.5%;
}

.slider-section {
    text-align: center;
}

blockquote {
    position: relative;
    margin: 0 1px;
    display: block;
    padding: 0 100px;

    font-size: 17px;
    font-weight: 500;
    text-align: center;
    line-height: 1.6;
    border: none;
    border-left: 0px;
    color: var(--color4);
    text-shadow: 0px 0px 20px var(--color18);



}

.feedback-overlay {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: block;
    content: "";
    background-color: var(--color26);
    z-index: 0;
}



/* .clearfix:after,
.clearfix:before,
slider-bg:before,
slider-bg:after {
    content: "";
    display: table;
} */

.pos {
    position: relative;
    height: 96px;
}


@media (min-width: 1200px) {
    .extra-padding {
        margin-top: 4.5%;
        padding-left: 54px;
        padding-right: 54px;
        margin-left: 15%;
        margin-right: 15%;
    }

    #slide1_images div {
        padding-left: 50px;
        padding-right: 50px;
        margin-bottom: 4.5%;
    }

}

@media (max-width: 1199px) and (min-width: 992px) {
    .extra-padding {
        margin-top: 4.5%;
        padding-left: 54px;
        padding-right: 54px;
        margin-left: 15%;
        margin-right: 15%;
    }

    #slide1_images div {
        padding-left: 50px;
        padding-right: 50px;
        margin-bottom: 6%;
    }
}

@media (max-width: 991px) and (min-width: 769px) {
    .extra-padding {
        padding-left: 54px;
        padding-right: 54px;
        margin-left: 15%;
        margin-right: 15%;
    }

    #slide1_images div {
        padding-left: 50px;
        padding-right: 50px;
        margin-bottom: 7%;
    }
}

@media(width:768px) {
    #slide1_images div {

        margin-bottom: 8%;
    }
}

@media (max-width: 767px) and (min-width: 600px) {
    #slide1_images div {

        margin-bottom: 14%;
    }
}

@media (max-width: 599px) and (min-width: 450px) {
    #slide1_images div {

        margin-bottom: 19%;
    }
}

@media (max-width: 768px) and (min-width: 450px) {
    .extra-padding {
        padding-left: 5px;
        padding-right: 5px;
        margin-left: 8%;
        margin-right: 8%;
    }

    .alignpadding {
        padding-top: 5%;
    }

    blockquote:before {
        content: url("");
    }

    blockquote:after {
        content: url("");
    }
}

@media (max-width: 449px) and (min-width: 321px) {
    #slide1_images div {
        margin-bottom: 26%;
    }
}

@media (max-width: 320px) {
    #slide1_images div {
        margin-bottom: 29%;
    }
}

@media (max-width: 449px) {
    .extra-padding {
        padding-left: 5px;
        padding-right: 5px;
        margin-left: 5%;
        margin-right: 5%;
    }

    blockquote:before {
        content: url("");
    }

    blockquote:after {
        content: url("");
    }

    .slider-bg {
        background-position: center;
    }
}




#feedback-controls .customer-details {
    -webkit-transition: border 0.3s ease, opacity 0.3s ease;
    -moz-transition: border 0.3s ease, opacity 0.3s ease;
    -o-transition: border 0.3s ease, opacity 0.3s ease;
    transition: border 0.3s ease, opacity 0.3s ease;
    opacity: 0.75;
    cursor: pointer;
    vertical-align: bottom;
    width: 100%;
    bottom: 0;
    text-align: center;
    margin: 0 auto;
}

@media (max-width: 768px) {

    .slider-bg {
        background-attachment: scroll;
        background-size: cover;
        background-position: center bottom;
    }

    blockquote {
        text-shadow: 0 0 0;
    }
}

@media(max-width:767px) {
    .alignpadding {
        padding-top: 6%;
    }
}

/* Ipad */

@media (min-width: 768px) {
    @media (min-height: 1024px) {
        .slider-bg {
            background-attachment: scroll;
        }
    }
}

@media (min-width: 1024px) {
    @media (min-height: 768px) {
        .slider-bg {
            background-attachment: scroll;
        }
    }
}

/* 
            *
            *new Slider ends
            *
        */


/* 
            *
            *workarea starts
            *
        */
.agility-heading {
    font-size: 26px;
    text-align: center;
    color: var(--color27);
    padding-top: 1em;
    font-weight: bold;
}

.agility-heading span {
    color: var(--jile-color);
}

@media (max-width: 767px) {

    .agility-heading {
        font-size: 24px;
    }

    .lrpadd {
        padding-left: 10%;
        padding-right: 10%;
    }
}

.padd-0 {
    padding-top: 0px;
}

.lrpadd {
    padding-left: 5%;
    padding-right: 5%;
}

.workarea {
    color: var(--color27);
    min-height: 21em;
    padding: 0% 7%;

}

.workarea-heading {
    text-align: center;
    font-size: 24px;
    color: var(--color27);
}

.workarea-sub-heading {
    text-align: center;
    font-size: 18px;
    color: var(--color27);
}

.workarea ul {
    list-style: disc;
    padding-left: 7%;
    font-weight: bold;
}

.workarea li {
    font-size: 150%;
    line-height: 1em;
}

.workarea li span {
    font-size: 16px;
    vertical-align: bottom;
}

.workarea-shadow-one {
    border-radius: 8px;
    position: relative;
    background-color: var(--color4);
    box-shadow: 0px 0px 10px 0px var(--color1);

}

/* .imgone {
    background-image: url('/assets/img/background/TeamGrad.png');
    background-size: cover;
}

.jq-workarea-shadow-one {
    background-image: url('/assets/img/background/TeamBG.png');
    background-size: auto 50%;
    background-repeat: no-repeat;
    background-position: bottom right;
    box-shadow: 0 0 12px 5px #a2c3f2;
} */

.workarea-shadow-two {
    border-radius: 8px;
    position: relative;
    background-color: var(--color4);
    box-shadow: 0px 0px 10px 0px var(--color1);

}

/* .imgtwo {
    background-image: url('/assets/img/background/ProgramGrad.png');
    background-size: cover;
}

.jq-workarea-shadow-two {
    background-image: url('/assets/img/background/ProgramBG.png');
    background-size: auto 50%;
    background-repeat: no-repeat;
    background-position: bottom right;
    box-shadow: 0 0 12px 5px #faf588;
} */

.workarea-shadow-three {
    border-radius: 8px;
    position: relative;
    background-color: var(--color4);
    box-shadow: 0px 0px 10px 0px var(--color1);

}

/* .imgthree {
    background-image: url('/assets/img/background/EnterprisesGrad.png');
    background-size: cover;
}

.jq-workarea-shadow-three {
    background-image: url('/assets/img/background/EnterprisesBG.png');
    background-size: auto 50%;
    background-repeat: no-repeat;
    background-position: bottom right;
    box-shadow: 0 0 12px 5px #9bf694;
} */

.padding5 {
    padding: 12px;
    color: var(--color27);
}

.mar-bottom2 {
    margin-bottom: 5px;
}

.workarea-heading-one {
    padding: 0.8em;
    border-radius: 8px;
}

/* .jq-workarea-heading-one {
    background-image: url("/assets/img/background/BlueWave.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
} */

.workarea-heading-two {
    padding: 0.8em;
    border-radius: 8px;
}

/* .jq-workarea-heading-two {
    background-image: url("/assets/img/background/YellowWave.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
} */

.workarea-heading-three {
    padding: 0.8em;
    border-radius: 8px;
}

/* .jq-workarea-heading-three {
    background-image: url("/assets/img/background/GreenWave.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
} */

/* 
            *
            *workarea ends
            *
        */

#triangle-bottomright {
    width: 0;
    height: 0;
    border-bottom: 11em solid var(--color4);
    border-left: 100vw solid transparent;
    position: absolute;
    bottom: 0px;
}




.modal {
    display: none;
    /*position: fixed; */
    z-index: 200;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: var(--color18);
    background-color: rgba(0, 0, 0, 0.4);
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
}

.modal-content {
    position: fixed;
    background-color: var(--color29);
    margin: 10% auto;
    padding: 10px 20px 20px;
    border: 1px solid var(--color30);
    width: 40%;
    min-height: 400px;
    bottom: auto;
    right: auto;
    top: 0%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 25% center;
    border: 10px solid var(--color4);
}

.opt-in-form-content {
    background-image: url('/assets/img/homePage/webinarPopup.jpg');
}

.modal-form-content {
    width: 50%;
    top: 30px;
    position: relative;
    background-color: transparent;
}

.popup-header {
    font-size: 30px;
    font-weight: 600;
    padding-bottom: 1.5em;
    padding-top: 10px;
    line-height: 1;
}

.modal-form-bottom-content {
    font-size: 15px;
    font-weight: 400;
    color: var(--color18);
    margin-top: 3em;
    margin-bottom: 15px;
}

.modal-form-bottom-content p {
    margin: 0;
    font-size: 13px;
    line-height: 1.2em;
}

.text-black {
    color: var(--color18) !important;
    cursor: pointer;
}

@media (max-width:767px) {
    .modal {
        display: none !important;
    }
}

@media (max-width:450px) {
    .modal-content {
        top: 4% !important;
        min-width: 300px;
        background-position: 30%;
    }

    .modal-form-content {
        width: 100% !important;
        top: 10px !important;
    }

    .popup-header {
        padding-top: 0px !important;
    }
}

@media (max-width:1024px) {
    .modal-content {
        width: 50%;
        left: 50% !important;
        right: 50% !important;
        margin-left: -25%;
        margin-right: -25%;
    }

    .modal-form-content {
        top: 10px;
    }
}

@media (max-width:991px) {
    .modal-content {
        background-position: 35% center;
    }

}

@media (min-width:1025px) {
    .modal-content {
        left: 60% !important;
        right: 60% !important;
        margin-left: -30%;
        margin-right: -30%;
    }
}


.case-study-lg {
    border: 1px solid var(--color19);
    margin-bottom: 30px;
    cursor: default;
}

.case-study-contant {
    padding: 20px 30px 30px;
}

.case-study-title {
    margin: 0 0 5px;
    line-height: .6em;
}

.case-study-lg p {
    color: var(--color1);
}

.case-study-lg img,
.row img {
    width: 100%;
}

.case-study-footer {
    border-top: 1px solid;
    border-top-color: var(--color20);
    padding-top: 20px;
    text-align: right;
}

.case-study-title a {
    font-size: 25px;
    color: var(--color18);
    cursor: pointer;
    display: block;
}

.case-study-title a:hover {
    color: var(--jile-color);
}

/* .magic-quadrant {
    background-color: var(--jile-color);
    padding: 1em 0;
    color: var(--color4);
    text-align: center;
    font-size: 22px;
}

.magic-quadrant-content {
    padding: 0px 50px;
}

.magic-quadrant-content::before {

    left: 0;
}

.magic-quadrant-content::after {

    right: 0;
}

.magic-quadrant-content::before,
.magic-quadrant-content::after {
    background-image: url('/assets/img/homePage/trophyWhite.png');
    background-size: 100% 80%;
    display: inline-block;
    background-repeat: no-repeat;
    width: 50px;
    height: 50px;
    content: "";
    position: absolute;
    top: 0;

}

.magic-quadrant-link a {

    color: var(--color4);

    font-weight: 600;
    font-size: 18px; */
/*     
    text-decoration: underline;


}

.magic-quadrant-link a:hover {
    text-decoration: underline;
}

@media (max-width:991px) {
    .magic-quadrant-padding {
        display: none;
    }
}

@media (max-width:767px) {

    .magic-quadrant-content::before,
    .magic-quadrant-content::after {
        display: none;
    }
}

@media (max-width:375px) {
    .magic-quadrant-content::before {
        display: block;
        position: relative;
        left: 35%;
        width: 70px;
        height: 100px;
    }
} */


/* .trophy {
    background-image: url('/assets/img/homePage/trophyWhite.png');
    background-size: 80% auto;
    background-position: center center;
} */

.gartner-section{
    background-image: url('/assets/img/homePage/gartnerImg.png');
    padding: 3em 0;
    background-size: cover;
    background-position: right center;
    font-weight: 600;
    color: var(--color2);
}
.gartner-section p{
    font-size: 26px;
    font-family: sans-serif;
    font-weight: 500;
}
.gartner-section a{
    font-weight: 600;
}
.gartner-section .fa{
    vertical-align: middle;
}
@media (max-width:767px){
    .gartner-section{
        background-position: left center;
    }
}


/*
  *
  *product-tour
  *
*/
.product-tour-outer {
    background-image: url('/assets/img/homePage/videobg.png');
    padding: 2em 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    /* color: white; */
    color: var(--color2);
  }
  .product-tour-text{
      margin-top: 3%;
  }
  .product-tour-text hr{
    border: 1px solid var(--jile-color);
}
  .product-tour-outer p{
    font-size: 18px;
    /* text-align: center; */
    text-align: left;
  }

  
.thumbnail {
  margin-bottom: 0;
  background: transparent;
  border: 0 !important;
}
.product-tour-video>div>img{
    cursor: zoom-in;
    /* box-shadow: 0 0 5px var(--color2); */
    border-radius: 5px;
}

  @media (max-width:1199px) {
    .product-tour-text{
        margin-top: 0;
        }
  }
  @media (max-width:991px) {
  .product-tour-video{
    margin-top: 7em;
  }
  }
  @media (max-width:767px) {
    .product-tour-video{
      margin-top: 0;
      float: none !important;
    }
    .product-tour-outer{
      padding: 2em 1em;
    }
  }
  @media (min-width:768px){
    .product-tour{
      padding: 0 2em;
    }
  }
  @media (max-width:767px){
    .product-tour-text{
      padding-bottom: 2em;
    }
  }
  /*
    *
    *jile-agile-test video
    *
  */

  /*
  *
  *built-in quality
  *
*/
.builtin-quality {
    padding: 4em 0;
  }
  .builtin-quality-text{
    padding-right: 8em;
    color: var(--color2);
    margin-top: 1em;
  }
  .builtin-quality-text.text-space-right{
      padding: 0 4em;
  }
  .builtin-quality h3 {
    /* color: var(--jile-color); */
    color: #404040;
    font-size: 24px;
    font-weight: 600;
    /* margin-top: 1em; */
  }

  .builtin-quality ul {
    margin-left: 1.2em;
    line-height: 2em;
  }  
  .builtin-quality ul li:before {   
    content: "\f05d";
    font-family: 'FontAwesome';
    display: block;  
    height: 0;  
    width: 0;  
    left: -1.2em;
    /* top: 1px; */
    position: relative;
    color: var(--jile-color);
} 
  .builtin-quality-text p:nth-of-type(1){
    margin-top: 1em;
  }
  .builtin-quality img{
    width: 95%;
    margin: 0 auto 10px;
    max-width: 28em;
  }
  /* .builtin-quality-text{
    margin-top:5em;
  } */
  .strategic .color-bg{
    background-color: #e4f2fd;
  }
  @media (max-width:1199px) {
    .builtin-quality-text {
        margin-top: 0;
      }
  }
  @media (max-width:991px) {
    .builtin-quality {
      padding: 2em 0;
    }
    .builtin-quality img{
      margin-top: 4.5em;
    }
    .builtin-quality-text.text-space-right {
      padding: 15px;
    }
    .builtin-quality-text {
      padding-right: 15px;
    }
  }
  @media (max-width:767px) {
    .builtin-quality .pull-right{
      float: none !important;
    }
    .builtin-quality img{
        max-width: 90%;
        display: block;
        margin-top: 0;
    }
  }

  /*
  *
  *video-play button
  *
*/
  .caption-overflow img {
    height: 40px!important;
    width: auto !important;
}

.caption-overflow span {
    top: 95%;
    text-align: right;
}