#footer {
    background: none;
    /* background-color: #505050; */
    background-color: var(--color8);
    color:var(--color4);
    padding: 0;
}
#footer a{
    color:var(--color4);
    line-height: 2;
}
#footer a:hover{
    text-decoration: underline;
}
.footer-content {
    /* padding: 7vh 0 0 0 ; */
    padding:5vh 0 4vh 0;
   
}
.footer-content  p{
    font-size: 15px;
    line-height: 1.6;
     margin: 0 0 10px;;
}
.footer-content ul{
	list-style: none;
	padding-left:0px;
}
.footer-content  li {
    position: relative;
    margin-bottom: 15px;
    padding-left: 30px;
    font-size: 15px;
    line-height: 1.6;
	
}
.footer-section-1{
    margin-bottom: 20px;
    margin-top: 30px;
}
.footer-section-2>div{
    padding:0px;

}
.footer-section-2 .row{
    margin:0px;
    padding:0px;
}
.footer-menu-wrapper{
    padding:0px;
}
.footer-menu-title{
    margin-bottom: 10px;
    font-weight: 600;
    font-size:20px;
    color:var(--color16);
    line-height: 2;
}
a.footer-menu-title{
    color:var(--color4);
   display: block;
}
.footer-menu-wrapper li{
    padding-left: 0px;
    margin-bottom: 5px;
}
.contact-us .fa{
    position: absolute;
    left: 0;
    bottom: 6px;
    font-size: 20px;
    color: var(--jile-color);
    padding-right: 5px;
}
.socials{
   
    /* text-align: center; */
    margin: 0 auto;
}
.socials  li{
     
        padding: 0 10px 10px;
    
}
.socials i{
    cursor: pointer;
}
.socials>li:first-child{
    padding-left:0px;
}
.socials .fa{
    color:var(--jile-color);
}
/*
    *
    * copyright
    * 
 */

#footer .copyright-container{
    background-color: var(--color9);
    padding: 1em 0;
    font-size: 12px;
    font-weight: 300;
}
.copyright-container .legal-pages-border {
    border-right: 1px solid #ccc;
    width: 1px;
    height: 12px;
    padding-left: 0;
    margin: -2px 0;
    padding: 0 !important;
}
.footer-head {
    background-color: var(--color3);
}
.logo-container-footer {

    height: inherit;
}

.logo-container-footer .logo {
   display: block;
    height: 50px;
    margin-top: 7px;
    background-image: url(/assets/img/logoWhite.png);
    background-repeat: no-repeat;
    background-position: left center;
    line-height: 30px;
    background-size: contain;
    margin-left: -30px;
}

@media(max-width:991px){
    #footer .copyright-container{
        text-align: center;
    }
    #footer .copyright-container .list-inline{
        float:none;
    }
    #footer .copyright-container .copyright{
        float:none;
    }
    .social-wrap .socials{
        text-align: center;
    }
    .footer-menu-wrapper{
        padding-right:10px;
    }
    .footer-section-2 .row{
        margin-bottom:20px;
    }
    .contact-us{
        text-align: center;
    }
    .contact-us li{
        display: inline-block;
        padding-right: 30px;
        margin-bottom: 10px;
    }
    .footer-content{
        padding: 4vh 0 1vh 0;
    }
    .logo-container-footer .logo{
        margin-left: 0;
    }
}

/* @media (max-width:767px){
    .social-wrap .socials{
        text-align: center;
    }
    .footer-menu-wrapper{
        padding-right:10px;
    }
    .footer-section-2 .row{
        margin-bottom:10px;
    }
} */

.social-wrap div{
	
}
.footer-section-2 .social-wrap-style{
	padding-top:40px;
	/*padding-bottom:20px;*/
	
}
.socials-style,.contact-us-style{
	padding:0px;
}
.socials-style i{
    color: var(--jile-color);
}
.socials-style div{
	padding-left:10px;
	padding-right:10px;
	display:inline-block;
}
.contact-us-style div{
	padding-left:10px;
	padding-right:10px;
	display:inline-block;
	    text-align: right;
}
.contact-us-style span{
	    color: var(--jile-color);
}
 @media (max-width:991px){
	 .socials-style,.contact-us-style{
		 float:none;
		 text-align:center;
		 padding-bottom:10px
	 }
 }
 
@media (min-width: 1024px){
@media (min-height: 768px){
  .footer-section-2>div>div{
	  width:100%;
  }
  .footer-section-2>div>div>div{
	  width:50%;
  }
 }
}