:root{
    /* --font-family1:Lato, sans-serif; */
    /* --font-family1:'Open Sans', sans-serif; */
    --font-family1: 'Source Sans Pro', sans-serif;
    --font-family2: 'Poppins', sans-serif;

	--color-new-1:#42099e;
	
	--color-new-2:#5a8fbe;
	
	--button-shadow-1:0px 5px 5px 0px #80808087;
	--button-shadow-2: 0px 3px 6px 0px #8080804f;
	
	--background-color-1:#c4c4c46b;
	
	
    --jile-color:#31c7b2;
    --color-menu-1: #006666;
    --color-menu-2: #006666f4;
    --color1:grey;
    --color2:#1d1d1d;
    
    --color3:#666666; /* jile iron color */
    --color4:white;
    
    --color5:#272727;
    --color6:whitesmoke;
    --color6-1:#7F7D7D;
    --color6-2:#464646;
    /*--color7:#141618;*/
	  --color7:#24344a;
    --color8:#505050;/* old footer*/
    --color9:#444242;/* copywrite */
    --color10:#000000cc;

    --color11:#f8d146;/* envision */
    --color12:#5a8fbe;/* elobrate */
    --color13:#E26A6A; /* plan */
    --color14:#9B59B6;/* measure */
    --color15:#26344B;
    --color16:#b1b1b1;
    --color17:#000000a6;
    --color18:#000000;
    --color19:#e7ecf1;
    --color20:#f0f1f2;
    --color21:#26c4ad;
    --color22:#333333;
    --color23:#333333c2;
    --color24:#454545;
    --color25:#4a4a4a;
    --color26:#00000040;
    --color27:#696868;
    --color28:#6d6a6a;
    --color29:#fefefe;
    --color30:#888;
    --color31:#f9f9f9;
	--color32:#2E3133;
	--color33:#44B6AE;/*dark jile color*/
	--color34:#3f3f3f;
	--color35:#c2cad8;
	--color36:#f7f7f7;
	--color37:#1697cb;
	--color38:#d69917;
	--color39:#4fadb7;
	--color40:#f66439;
	--color41:#6b17d6;
	--color42:#ba469e;
	
	
	--colorpartner1:#615482;
	
	
	
	
	--colorprice1:#38b4f9;
	--colorprice2:#c29d3e;
	
	
    --box-shadow1:0 2px 5px #0000001a;

    --border-color1:#00000026 transparent #ffffff0d;
}